import * as React from "react"
import Layout from "../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../../components/footer';
import Wrapper from '../../components/wrap';
import { Link } from "gatsby"
import FullLogo from "../../images/pp/full_logo.inline.svg";
import rebrand from "../../images/pp/rebrand.gif"
import kiosk from "../../images/pp/kiosk_cover.png"
import od from "../../images/pp/cover_od.png"
import mobile from "../../images/pp/cover_mobile_v2.png"
import pam from "../../images/pp/pam_v2.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
// import { Animator, ScrollContainer, ScrollPage, batch, Fade, MoveOut } from "react-scroll-motion";
import { ScrollContainer } from "react-scroll-motion";

const IndexPage = () => {

  return (
    <Layout navtype="fixed" title="Premium Parking" className={'fullsize'}>
      <ScrollContainer>
          <Wrapper className="first">
            {/* <p>
              
              <br />
              2016-2022. <a target="_blank" rel="noreferrer" href="https://www.premiumparking.com">Premium Parking</a> is the gateless digital parking in USA.
            </p> */}
            <FullLogo width="200" height="60" />
            <h1>The transition to a SaaS business model</h1>
            <ul className="types">
              <li>
                <small>Timeframe</small>
                <br />
                2017–2022
              </li>
              <li>
                <small>Role</small>
                <br />
                Lead Product Design
              </li>
            </ul>

            <h3>My role and the team</h3>
            <p>
              The <a target="_blank" rel="noreferrer" href="https://www.premiumparking.com">Premium Parking</a> products has grown and changed, as have my areas of responsibility.
              As a member of the core team, I worked alongside a Project Managers, Engineers, CPO, and external teams.
              <br />
              I was responsible for defining the overall experience through mapping out the journey, user validation research, prototyping, setting requirements for data collection, improving cross-functional communications, generating hypothesis, and crafting high fidelity mockups throughout all stages of the project. Also, I found a balance between better experience and quick integration (3rd party integrations), made audit and review launched features in collaboration with teams from the United States 🇺🇸, India 🇮🇳, and Italy 🇮🇹.
            </p>
            <h3>Results</h3>
            <p>
              
              All our hard work resulted in us being <strong>ranked # 1 for Premium Parking's management solution</strong> in the USA, and gave <strong>growth of 10 times between 2018 and 2022</strong>. Some of the initiatives that made this possible include a complete <Link to="/projects/premium-parking/mobile">redesign of checkout flow</Link>, customer's communications improvements throughout the user journey, and countless smaller improvements across all touchpoints. And also <Link to="/projects/premium-parking/pam">products developed from scratch for parking owners</Link> made a great contribution to this growth.
            </p>
            <p>Below is a sampling of some of the more in-depth projects I've done throughout the years with Premium Parking.</p>
            </Wrapper>
            <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true" offset={0}>
              <ul className="sub-projects">
                <li>
                  <Link className="cover-textpay pp-cover" to="/projects/premium-parking/textpay">
                    <p className="pp-info">
                      <strong>Rethinking checkout flow for add-ons in TextPay and CameraPay</strong>
                      Increased revenue up to 20%, added 5 integrations, increased loyal users up to 12%
                    </p>
                    <StaticImage
                      placeholder="blurred"
                      formats={["AUTO", "WEBP", "AVIF"]}
                      src="../images/pp/cover_textpay_v2.png"
                      alt="2021-2022. Apply the new brand and successful experiments from other products."
                      className="cover"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/projects/premium-parking/od" className="pp-cover cover-od">
                    <p className="pp-info">
                      <strong>Shift the responsibility from operators to the parking lots owners</strong>
                      2021. Research and conceptualize a new IA prioritized by user roles
                    </p>
                    <img src={od} alt="2021. Research and conceptualize a new IA prioritized by user roles." className="cover"/>
                  </Link>
                </li>
                <li>
                  <Link to="/projects/premium-parking/mobile" className="pp-cover cover-mobile">
                    <p className="pp-info">
                      <strong>Polishing the parking purchase funnel in Premium Parking mobile apps</strong>
                      Improved conversion by +5.68%, and designed new onboarding, map screens, and design system for mobile apps.
                      DAU increased by 2 times.
                    </p>
                    <img src={mobile} alt="2018-2019. Rethink the checkout flow and whole architecture to support growth" className="cover" />
                  </Link>
                </li>
                <li>
                  <Link to="/projects/premium-parking/rebrand" className="pp-cover cover-rebrand">
                    <p className="pp-info">
                      <strong>A full rebrand of Premium Parking, including the design system</strong>
                      Added 5 integrations, shortered design and development time up to 20%
                    </p>
                    <img src={rebrand} alt="2019-2020. A full rebrand of Premium Parking, including a new, accessible the design system" className="cover cover-rebrand" />
                  </Link>
                </li>
                <li>
                  <Link to="/projects/premium-parking/pam" className="pp-cover cover-pam">
                    <p className="pp-info">
                      <strong>The transitioning of a usage-based revenue model to SaaS model with data-driven design approach</strong>
                      The number of markets increased by 4 times, and the number of parkings increased by 6 times
                    </p>
                    <div className="pam-img">
                      <img src={pam} alt="2017-2018. Give Parking owners the tools they need to accurately and easily manage 
                    their business" className="cover" />
                    </div>
                  </Link>
                </li>
                <li>
                <Link to="/projects/premium-parking/kiosks" className="pp-cover cover-kiosk">
                  <p className="pp-info">
                    <strong>Validation Kiosks, Outlook Plugin, Pay Machine</strong>
                    2020. Expanding the new brand for more products and extending the design system
                  </p>
                  <img src={kiosk} alt="2020. Expanding the new brand for more products and extending the design system" />
                </Link>
              </li>

              </ul>
            </AnimationOnScroll>
      <Footer />
    </ScrollContainer>
</Layout>
  )
}

export default IndexPage
